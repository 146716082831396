$body-font: 'Open Sans',
sans-serif;
$header-font : 'Oswald',
sans-serif;

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    background-image: url("https://avante.biz/wp-content/uploads/Black-Wallpapers-Texture/Black-Wallpapers-Texture-050.jpg");
    background-repeat: repeat-y;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    color: white;
    font-family: $body-font;

    img {
        width: 100%;
    }

    h1 {
        font-family: $header-font;
    }

    h2 {
        @extend h1;
    }

    h3 {
        @extend h1;
    }

    input {
        color: black;
    }

    .navbar-inverse {
        background-color: rgba(000, 000, 000, 0.5);
        height: 100%;
        width: auto;
        padding: 1%;

        li {
            font-size: 2em;
            margin: 0 20px;
        }

        img {
            width: 50%;
        }

    }

    .error {
        color: red;

        &_border {
            border: solid 1px red;
        }

        &_hide {
            display: none;
        }
    }

    .footer {
        margin-top: 10%;
    }
}