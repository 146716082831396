.welcomeHeading {
    margin: 2% 0;
    text-align: center;
}

.overlay {
    background: rgba(000, 000, 000, 0.8);
    position: absolute;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
}

.landingPage {
    position: relative;

    .landingPage__logo {
        margin: 13% 0;
    }

    .landingPage__button {
        text-align: center;

        .callToAction {
            margin-top: 15%;
            color: white;
            background-color: #000;
            padding: 5% 10%;
            letter-spacing: 10px;
            font-size: 1.5em;
            border-radius: 8px;
            border: 1px solid whitesmoke;
        }

        .callToAction:hover {
            border-color: #f3f74f;
            color: #f3f74f;
        }
    }
}

.projectContainer {
    margin-top: 10%;

    .cardContainer {
        margin-top: 50px;
    }

    .card {
        filter: grayscale(1);
        transition: all .3s ease-in-out;
        margin: 20px 15px;
        padding: 10px;
        height: auto;
        background-color: rgba($color: #000000, $alpha: 0.8);

        img {
            margin: 5% 0;
        }
    }

    .card:hover {
        filter: none;
        box-shadow: 0px 5px 30px 0.5px #ffffff;
        transform: scale(1.1);
    }

}