.Heading {
    margin: 10% 0;
    text-align: center;
}

.jobcontainer {
    margin-top: 5%;

    img {
        padding: 5%;
    }
}

.educationcontainer {
    margin-top: 5%;
}