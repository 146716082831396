.about {
    .about__welcome {
        text-align: center;
    }

    .about__content {
        img {
            margin: 20px 0;
        }

        p {
            font-size: 1.5em;
        }
    }



    .about__description {
        margin-top: 10px;
    }
}